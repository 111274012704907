<template>
  <div class="wap-mine">
    <div class="top-info flex-center">
      <div class="logo">
        <img :src="shopInfo.Logo || headerUrl" alt="" />
      </div>
      <div class="flex-1 info">
        <div class="username">{{ shopInfo.Name }}</div>
        <div class="userId flex-center-start">
          {{ shopInfo.ID }}
          <i class="el-icon-copy-document copy-icon" @click="copyData"></i>
        </div>
      </div>
      <div class="level shop-level mine" :class="`level-${shopLevel}`">
        <div class="level">
          {{ $t('deng-ji') }}
          {{ levelName }}
        </div>
      </div>
    </div>

    <div class="level-info flex-center-between">
      <div class="shop-level" :class="`level-${shopLevel}`"></div>
      <div class="percent-outer">
        <div class="percent" :style="`width: ${percent}%`"></div>
        <div class="percent-label" :style="`left: ${percent}%`">
          {{ percent }}%
        </div>
      </div>
      <div class="shop-level" :class="`level-${shopLevel + 1}`"></div>
    </div>

    <div class="count-info">
      <div class="label">
        {{ $t('dang-qian-yun-hang-zi-jin') }}
        <span class="yellow">${{ totalMoney }}</span
        >{{ $t('ju-li-deng-ji') }}
        {{ nextLevelName }}
        {{ $t('huan-xu') }}
        <span class="yellow">${{ upgradeMoney.toFixed(2) }}</span>
      </div>
    </div>

    <div class="money-info">
      <div class="flex-center-start">
        <span class="money"
          >${{ showMoney ? userMoney.toFixed(2) : '****' }}</span
        >
        <img
          :src="eyeUrl"
          alt=""
          v-if="showMoney"
          @click="showMoney = !showMoney"
        />
        <img :src="eyeUrl2" alt="" v-else @click="showMoney = !showMoney" />
        <i class="el-icon-refresh" @click="refresh"></i>
      </div>
      <div class="label">{{ $t('yu-e') }}</div>
      <div class="profit">{{ $t('lei-ji-shou-yi-0') }} ${{ totalProfit }}</div>
      <div class="btn flex-center-around">
        <Button @click="toPath('wapWithdraw')">{{ $t('ti-xian') }}</Button>
        <Button @click="toPath('wapRecharge')">{{ $t('chong-zhi') }}</Button>
      </div>
    </div>

    <div class="credit-line flex-center-between" @click="toPath('wapCredit')">
      <div class="flex-center">
        <img :src="creditUrl" alt="" />
        <span>{{ $t('dai-kuan-shen-qing-0') }}</span>
      </div>
      <div>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <div class="menu-list">
      <div
        class="menu"
        v-for="(item, i) in menuList1"
        :key="i"
        @click="toPath(item.path)"
      >
        <div class="flex-center">
          <img :src="item.icon" alt="" />
          <span class="name">{{ item.name }}</span>
        </div>
        <div class="flex-center">
          <span class="desc">{{ item.desc }}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="menu-list">
      <div
        class="menu"
        v-for="(item, i) in menuList2"
        :key="i"
        @click="toPath(item.path)"
      >
        <div class="flex-center">
          <img :src="item.icon" alt="" />
          <span class="name">{{ item.name }}</span>
        </div>
        <div>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="menu-list">
      <div
        class="menu"
        v-for="(item, i) in menuList3"
        :key="i"
        @click="toPath(item.path)"
      >
        <div class="flex-center">
          <img :src="item.icon" alt="" />
          <span class="name">{{ item.name }}</span>
        </div>
        <div>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
import Clipboard from 'clipboard'
import { shopInfo, shopLevelList } from '@/api/shop'
import { getWalletInfo } from '@/api/user'
export default {
  components: {
    Button
  },
  data() {
    return {
      eyeUrl: require('@/assets/imgs/icon-eye-black.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2-black.png'),
      creditUrl: require('@/assets/imgs/icon-credit4.png'),
      headerUrl: require('@/assets/imgs/header/header1.png'),
      shopLevel: 1,
      avatar: 3,
      level: -1,
      levelName: '',
      nextLevelName: '',
      showMoney: true,
      userMoney: 0,
      totalProfit: 0,
      shopInfo: {
        ShopLevel: {}
      },
      totalMoney: 0,
      nextUpgradeMoney: 0
    }
  },
  computed: {
    menuList1() {
      return [
        {
          icon: require('@/assets/imgs/icon-menu1.svg'),
          name: this.$t('ge-ren-xin-xi'),
          path: 'wapUserInfo'
        },
        {
          icon: require('@/assets/imgs/icon-menu2.svg'),
          name: this.$t('yu-yan'),
          path: 'wapLang',
          desc: this.langName
        },
        {
          icon: require('@/assets/imgs/icon-menu3.svg'),
          name: this.$t('tui-kuan-shen-qing'),
          path: 'wapRefund'
        }
      ]
    },
    menuList2() {
      return [
        {
          icon: require('@/assets/imgs/icon-menu4.svg'),
          name: this.$t('zi-jin-ji-lu'),
          path: 'wapDebt'
        },
        {
          icon: require('@/assets/imgs/icon-menu5.svg'),
          name: this.$t('cai-wu-bao-biao'),
          path: 'wapReport'
        }
      ]
    },
    menuList3() {
      return [
        {
          icon: require('@/assets/imgs/icon-menu6.svg'),
          name: this.$t('she-zhi-0'),
          path: 'wapSystem'
        }
      ]
    },
    langName() {
      return this.$store.state.langName
    },
    upgradeMoney() {
      if (this.nextUpgradeMoney) {
        return this.nextUpgradeMoney - this.totalMoney
      } else {
        return 0
      }
    },
    percent() {
      if (this.nextUpgradeMoney) {
        return parseFloat(
          ((this.totalMoney / this.nextUpgradeMoney) * 100).toFixed(2)
        )
      } else {
        return 0
      }
    }
  },
  mounted() {
    this.init()
    this.initMoney()
  },
  methods: {
    initMoney() {
      getWalletInfo().then((res) => {
        let data = res.data.Items[0]
        this.userMoney = data.Balance
        this.totalMoney = parseFloat((data.Balance + data.Freeze).toFixed(2))
      })
    },
    init() {
      shopInfo().then((res) => {
        this.shopInfo = res.data
        this.level = res.data.ShopLevel.Level || 1
        if (this.levelList.length > 0) {
          this.getLevelName()
        }
      })

      shopLevelList().then((res) => {
        this.levelList = res.data.Items
        this.levelList.sort((a, b) => {
          return a.Level - b.Level
        })
        if (this.level != -1) {
          this.getLevelName()
        }
      })
    },
    getLevelName() {
      let item = this.levelList.find((v) => {
        return v.Level == this.level
      })
      let nextItem = this.levelList.find((v) => {
        return v.Level == this.level + 1
      })
      if (item) {
        this.levelName = item.LevelName
      } else {
        this.levelName = this.levelList[0].LevelName
      }
      this.nextLevelName = nextItem ? nextItem.LevelName : ''
      this.nextUpgradeMoney = nextItem ? nextItem.UpgradeMoney : 0
    },
    copyData() {
      let data = this.shopInfo.ID
      const clipboard = new Clipboard('.copy-icon', { text: () => data })
      this.$message.success(this.$t('fu-zhi-cheng-gong'))
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    refresh() {
      this.initMoney()
    }
  }
}
</script>